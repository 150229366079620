import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBHKR2kmD_9uKUlfOsSNzsGmyZneT96twg",
  authDomain: "gestao-diferencial-3adcc.firebaseapp.com",
  projectId: "gestao-diferencial-3adcc",
  storageBucket: "gestao-diferencial-3adcc.appspot.com",
  messagingSenderId: "763168158603",
  appId: "1:763168158603:web:7bcea9e06dde6e195fb58e",
  measurementId: "G-393L29QD8T",
};

// init firebase
const app = firebase.initializeApp(firebaseConfig);

// init firestore service
const projectFirestore = firebase.firestore(app);
const projectStorage = firebase.storage(app).ref();

// export firestore
export { projectFirestore, projectStorage };
