import { ref } from "vue";
import { projectFirestore as firestore } from "../firebase/config";

const getLogs = () => {
  const logs = ref([]);
  const error = ref(null);

  const loadLogs = async () => {
    try {
      const response = await firestore.collection("logs").get();
      logs.value = response.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (err) {
      error.value = err.message;
    }
  };

  return { logs, loadLogs };
};

export default getLogs;
