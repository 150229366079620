<template>
  <div class="home">
    <nav>
      <button
        class="logs-button"
        @click="showCard = !showCard"
      >
        Histórico
      </button>
    </nav>
    <div class="parent-container">
      <div
        v-if="showCard"
        class="card"
      >
        <h2 class="cardh2">
          Logs
        </h2>
        <div class="carddiv">
          <ul class="cardul">
            <li
              v-for="log in paginatedLogs"
              :key="log"
              class="cardli"
            >
              {{ log.task }}
            </li>
          </ul>
          <div class="pagination">
            <button
              class="pagination-btn"
              :disabled="pageNumber === 0"
              @click="pageNumber--"
            >
              &#x2190;
            </button>
            <button
              class="pagination-btn"
              :disabled="(pageNumber + 1) * 3 >= logs.length"
              @click="pageNumber++"
            >
              &#x2192;
            </button>
          </div>
        </div>
        <button
          class="closeBtnCard"
          @click="showCard = false"
        >
          Fechar
        </button>
      </div>
    </div>
    <!-- <div v-if="error">{{ error }}</div> -->
    <div
      v-if="posts.length"
      class="layout"
    >
      <PostList :posts="posts" />
      <!-- <TagCloud :posts="posts" /> -->
    </div>
    <div
      v-else
      class="error-message"
    >
      Desculpe, não há requisições para exibir, mas você pode verificar o histórico (:
    </div>
  </div>
</template>

<script>
import getPosts from "../composables/getPosts";
import getLogs from "../composables/getLogs";

// component imports
import PostList from "../components/PostList.vue";
// import TagCloud from '../components/TagCloud.vue'

export default {
  name: "HomePage",
  components: { PostList },
  setup() {
    const { posts, error, load } = getPosts();
    const { logs, loadLogs } = getLogs();
    load({ field: "pendente", operator: "==", value: true }); // Adiciona aqui a condição da requisição por página!
    loadLogs();
    return { posts, error, logs };
  },
  data() {
    return {
      pageNumber: 0,
      showCard: false,
    };
  },
  computed: {
    paginatedLogs() {
      return this.logs.slice(this.pageNumber * 3, this.pageNumber * 3 + 3);
    },
  },
};
</script>

<style>
.error-message {
  font-size: 20px;
  text-align: center;
  padding-top: 50px;
  color: #333;
}

.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.layout {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
}

.logs-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.logs-button:hover {
  background-color: #3e8e41;
}

.card {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 20px;
}

.cardh2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cardul {
  /* max-height: 120px; altura máxima para exibir 2 itens */
  /* overflow-y: auto; torna a lista rolável */
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cardli {
  margin-bottom: 5px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button.closeBtnCard {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination-btn {
  background-color: rgb(11, 190, 11);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.pagination-btn::after {
  content: "\2192"; /* código para seta direita */
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.pagination-btn:first-child {
  margin-right: 5px;
  margin-left: 0;
}

.pagination-btn:last-child {
  margin-right: 0;
  margin-left: 5px;
}

.pagination-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
