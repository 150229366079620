import { projectFirestore } from "@/firebase/config";
import { ref } from "vue";

const getPost = (id, name) => {
  const post = ref([]);
  const error = ref(null);

  const load = async () => {
    try {
      let res = await projectFirestore.collection(name).doc(id).get();
      const data = res.data();
      // Campos obrigatórios que tem em todas as coleções! // Isto define a ordem deste campos em todas as coleçoes...
      const _ = {
        title: data.title,
        empresa: data.empresa,
        pendente: data.pendente,
      };
      // Campos individual por coleção.... // Isto define a ordem deste campos em na coleção especificada....
      const collections = {
        Demissao: { ..._, ...data },
        Ferias: { ..._, ...data },
        RecalculoFiscal: { ..._, ...data },
        RecalculoDP: { ..._, ...data },
        Admissao: { ..._, ...data },
        Despesas: { ..._, ...data },
      };
      post.value = collections[name];
    } catch (err) {
      error.value = err.message;
    }
  };

  return { post, error, load };
};

export default getPost;
