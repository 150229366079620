<template>
  <div
    v-if="
      post.title && post.empresa && post.title !== '' && post.empresa !== ''
    "
    class="post"
  >
    <router-link
      :to="{
        name: 'Details',
        params: { id: post.id, title: post.name, empresa: post.empresa },
      }"
    >
      <h3 class="post-title">
        {{ post.empresa }}
      </h3>
      <div class="post-content">
        <h3 class="post-subtitle">
          {{ post.title }}
        </h3>
        <h4 class="post-date">
          {{ post.month }} {{ post.year }}
        </h4>
        <h4
          v-if="post.pis"
          class="post-label"
        >
          PIS
        </h4>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true
    },
  },
  setup() {},
};
</script>

<style>
.post {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 20px;
}

.post-title {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
}

.post-subtitle {
  margin-top: 0;
  font-size: 18px;
  font-weight: bold;
}

.post-content {
  display: flex;
  flex-direction: column;
}

.post-date {
  margin-bottom: 10px;
  color: #aaa;
}

.post-label {
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: #eee;
  color: #555;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
