<template>
  <div class="post-list">
    <div
      v-for="post in posts"
      :key="post.id"
    >
      <SinglePost :post="post" />
    </div>
  </div>
</template>

<script>
// component imports
import SinglePost from "./SinglePost.vue";

export default {
  components: { SinglePost },
  props: {
    posts: {
      type: Object,
      required: true,
    },
  },
  setup() {},
};
</script>

<style>
.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}
</style>
