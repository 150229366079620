import { projectStorage } from "@/firebase/config";
import { projectFirestore } from "@/firebase/config";
import { ref } from "vue";

const useStorage = () => {
  const error = ref(null);

  const uploadFile = async (file, name, id, title) => {
    if (!file) {
      return;
    }

    try {
      const progressBar = document.querySelector(".progress");
      const storageRef = projectStorage.child(file.name);
      const uploadTask = storageRef.put(file);

      progressBar.style.width = `${10}%`;

      uploadTask.then(async () => {
        // Completed
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        const date = new Date();
        // Atualiza o documento no Firestore com o URL do arquivo
        try {
          const promises = [
            projectFirestore.collection(name).doc(id).update({
              pendente: false,
              url: url,
            }),
            projectFirestore.collection("logs").add({
              task: `Solicitação - ${title} - ${name} - ${date.toDateString()} - Completo`,
            }),
            projectFirestore
              .collection(
                `Files/${title}/year/${date.getUTCFullYear()}/${
                  date.getUTCMonth() + 1
                }`
              )
              .add({
                fileParent: name,
                filename: file.name,
                fileurl: url,
              }),
          ];
          await Promise.all(promises);
        } catch (e) {
          console.log(e);
        }
      });

      progressBar.style.width = `${50}%`;

      uploadTask.on(
        "state_changed",
        async () => {},
        (error) => {
          console.error("Erro ao fazer upload do arquivo:", error);
        },
        () => {
          progressBar.style.width = `${100}%`;
        }
      );
    } catch (err) {
      console.error("Erro ao fazer upload do arquivo:", err);
      error.value = err.message;
    }
  };

  return { uploadFile };
};

export default useStorage;
