import { ref } from "vue";
import { projectFirestore as firestore } from "../firebase/config";

const collections = [
  "Ferias",
  "RecalculoFiscal",
  "RecalculoDP",
  "Admissao",
  "Demissao",
  "Despesas",
];

const getCollection = async (collection, condition = null) => {
  //  { field: 'status', operator: '==', value: 'pending' }
  let snapshot;
  if (condition) {
    const { field, operator, value } = condition;
    snapshot = await firestore
      .collection(collection)
      .where(field, operator, value)
      .get();
  } else {
    snapshot = await firestore.collection(collection).get();
  }

  return snapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    name: collection,
  }));
};

const getPosts = () => {
  const posts = ref([]);
  const error = ref(null);

  const load = async (condition = null) => {
    try {
      const promises = collections.map((collection) =>
        getCollection(collection, condition)
      );

      const results = await Promise.all(promises);
      posts.value = results
        .flat()
        .filter(
          (post) =>
            post.title &&
            post.empresa &&
            post.title !== "" &&
            post.empresa !== ""
        );
    } catch (err) {
      error.value = err.message;
    }
  };

  return { posts, error, load };
};

export default getPosts;
