<template>
  <div v-if="error">
    {{ error }}
  </div>
  <div
    v-if="post"
    class="post"
  >
    <span
      v-if="post.length <= 0"
      class="items-spinner"
    >
      <span>Carregando...</span>
    </span>
    <ul
      v-else
      id="list-items"
    >
      <template v-for="(value, key) in post">
        <li
          v-if="key !== 'url'"
          :key="key"
          class="items"
          :class="{ 'list-item-key': true, 'list-item-value': true }"
        >
          <span class="item-key">{{ map[key] ?? `${key} - ` }} </span>
          <span class="item-value">{{
            value.toString().replace("true", "Sim").replace("false", "Não")
          }}</span>
        </li>
      </template>
    </ul>
    <div>
      <button
        v-if="post.url"
        class="download-btn"
        @click="downloadFile(post.url)"
      >
        Download PDF
      </button>
    </div>
    <div>
      <input
        id="file-upload"
        ref="fileInput"
        type="file"
        class="hidden"
        accept="application/pdf"
        @change="uploadFile"
      >
      <button
        class="upload-btn"
        @click="chooseFile"
      >
        Escolher Arquivo
      </button>
      <button
        v-if="file"
        class="upload-btn"
        @click="sendFile"
      >
        Enviar Arquivo
      </button>
      <p v-if="file">
        {{ file.name }}
      </p>
      <div class="progress-bar">
        <div class="progress" />
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>

<script>
import getPost from "@/composables/getPost";
import useStorage from "@/composables/useStorage";
import { useRoute } from "vue-router";

// component imports
import Spinner from "../components/Spinner.vue";

export default {
  components: {
    Spinner,
  },
  props: {
    id: String,
    title: String,
    empresa: String,
  },
  setup() {
    const route = useRoute();
    const { post, error, load } = getPost(route.params.id, route.params.title);

    load().then(() => {});

    return {
      error,
      post,
    };
  },
  data() {
    return {
      file: null,
      map: {
        name: "Nome - ",
        nome: "Nome - ",
        tel: "Telefone - ",
        vacation_days: "Dias de férias - ",
        vacation_startingdate: "Ínicio de férias - ",
        title: "Título - ",
        vacation_status: "Status - ",
        true: "Sim",
        year: "Ano",
        month: "Mês de referência - ",
        FTGS: "FGTS - ",
        funcao: "Função - ",
        cpf: "CPF - ",
        data_contracao: "Data da contratação - ",
        salario: "Salário - ",
        trabalhado: "Aviso Prévio Trabalhado ? - ",
        firedata: "Data da demissão - ",
        empresa: "Empresa - ",
      },
    };
  },
  created() {},
  methods: {
    chooseFile() {
      this.$refs.fileInput.click();
    },

    uploadFile(event) {
      const selectedFile = event.target.files[0];
      this.file = selectedFile;
      // You can now do whatever you want with the file
    },

    sendFile() {
      const { uploadFile } = useStorage();
      uploadFile(this.file, this.title, this.id, this.empresa);
    },

    downloadFile(uri) {
      const downloadURI = (uri, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      downloadURI(uri, "document.pdf");
    },
  },
};
</script>

<style scoped>
.post {
  max-width: 1200px;
  margin: 0 auto;
}

.post p {
  color: #444;
  line-height: 1.5em;
  margin-top: 40px;
}

.pre {
  white-space: pre-wrap;
}

/* Estilo para a lista de items */
ul#list-items {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
}

/* Estilo para cada item */
/* .list-item-value {
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  color: #333;
  font-size: 18px;
  margin-bottom: 12px;
  padding: 12px 16px;
  text-align: center;
  width: 300px;
} */

/* Estilo para a key */
.item-key {
  color: #555;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

/* Estilo para o value */
.item-value {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.upload-btn {
  padding: 10px 20px;
  background-color: #2ecc71;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.download-btn {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}

.file-label {
  display: block;
  margin-bottom: 10px;
}

.hidden {
  display: none;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
}

.progress {
  width: 0%;
  height: 100%;
  background-color: #4caf50;
  margin-top: 10px;
}
</style>
